@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700&display=swap");
/* @import "css/style.css"; */

@layer components {
  .header {
    @apply container py-4 mt-4 sm:mt-12;
  }
  /* Icon size */
  .ic-s {
    @apply w-4 h-4;
  }
  .ic-m {
    @apply w-5 h-5;
  }
  .ic-l {
    @apply w-6 h-6;
  }
  .ic-xl {
    @apply w-9 h-9;
  }
  /* .link {
    @apply;
  } */
  .mobile-menu-link {
    @apply text-h5 font-semibold text-contrast dark:text-contrast-dark;
  }
  .title-brand {
    @apply text-h4 font-semibold text-contrast dark:text-contrast-dark;
  }
  .title-1 {
    @apply text-h6 text-contrast dark:text-contrast-dark;
  }

  .title-2 {
    @apply text-l font-semibold md:font-medium text-contrast dark:text-contrast-dark;
  }

  .title-3 {
    @apply text-l uppercase font-medium text-contrast dark:text-contrast-dark;
  }

  .title-4 {
    @apply text-l font-medium text-subtle dark:text-contrast-dark;
  }

  .caption {
    @apply text-xs text-subtle dark:text-subtle-dark;
  }

  .text-body {
    @apply text-xm md:text-m text-default dark:text-default-dark;
  }

  .ic-color {
    @apply text-default dark:text-default-dark;
  }

  .crumbs {
    @apply text-interactive dark:text-interactive-dark text-s font-semibold;
  }

  .thead-th {
    @apply px-5 py-3 font-medium;
  }
  .thead {
    @apply h-10
      border-b-2
      border-subtler dark:border-subtler-dark
      bg-hover dark:bg-hover-dark
      text-subtle-dark dark:text-subtle
      text-s text-left uppercase
      tracking-wider;
  }

  .input {
    @apply block w-full p-3 mt-1 bg-light dark:bg-dark 
      text-m text-default dark:text-default-dark placeholder-subtle
      border border-border rounded-md shadow-sm
      focus:outline-none focus:border-focus focus:ring-1 focus:ring-focus
      dark:focus:border-focus-dark dark:focus:ring-focus-dark
      invalid:border-error invalid:text-error
      dark:invalid:border-error-dark dark:invalid:text-error-dark
      focus:invalid:border-error focus:invalid:ring-error
      dark:focus:invalid:border-error-dark dark:focus:invalid:ring-error-dark;
  }

  .input--md {
    @apply w-full md:w-96;
  }
  .input-label-required {
    @apply block text-s font-medium text-subtle dark:text-subtle-dark after:content-['*'] after:ml-0.5 after:text-error font-medium;
  }

  .input-label {
    @apply block text-xs text-subtle dark:text-subtle-dark font-bold mb-2 uppercase tracking-wide;
  }

  .button {
    @apply appearance-none block p-3 leading-tight rounded
      font-semibold text-m
      text-light dark:text-dark
      w-40
      hover:cursor-pointer
      border border-subtler dark:border-subtler-dark;
  }
  .button--full {
    @apply w-full;
  }
  .button--submit {
    @apply hover:bg-interactive dark:hover:bg-interactive-dark 
    bg-brand dark:bg-brand-dark;
  }
  .button--download {
    @apply hover:bg-success dark:hover:bg-success-dark 
    bg-success dark:bg-success-dark;
  }
  .button--minimal {
  }
  .button--primary {
  }
  .button--delete {
    @apply hover:bg-error dark:hover:bg-error-dark 
    bg-error dark:bg-error-dark;
  }
  .button--confirm {
  }
  .button--cancel {
    @apply text-brand hover:text-interactive hover:bg-hover hover:border-brand border-subtler
      dark:text-brand-dark dark:hover:text-interactive-dark dark:hover:bg-hover-dark dark:hover:border-brand-dark;
  }
  .button--ghost {
    @apply appearance-none w-auto text-s 
      text-brand hover:text-interactive
      dark:text-brand-dark dark:hover:text-interactive-dark;
  }

  /* Fidelity */
  .fid-btn {
    @apply rounded shadow p-2
      flex items-center;
  }
  .fid-icon {
    @apply w-8 h-8 text-brand mr-3;
  }
}

:root {
  /* font-size: 16px;
  font-family: "Open Sans"; */
  /* --text-primary: #4b5563; */
  --text-primary: #b6b6b6;
  /* --text-secondary: #4b5563; */
  --text-secondary: #ececec;
  /* --bg-primary: #f3f4f6; */
  --bg-primary: #23232e;
  /* --bg-secondary: #e3e5e6; */
  --bg-secondary: #141418;
  --transition-speed: 600ms;
}
/* 
#root {
  height: 100vh;
}
.App {
  height: 100vh;
}
html body {
  height: 100%;
} */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 0.35rem;
  height: 0.3rem;
}

::-webkit-scrollbar-track {
  background: #1e1e24;
}
div::-webkit-scrollbar-track {
  background: #f6f6f6;
}
div::-webkit-scrollbar-thumb {
  background: #dedede;
}

::-webkit-scrollbar-thumb {
  background: #6649b8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  /* margin-left: 5rem; */
  /* padding: 1rem; */
}

/* Create QR Code */
.columns {
  height: 100%;
}

.column.no-scroll {
  overflow: auto;
}

.full {
  height: 100vh;
}

.radio-buttons {
  width: 100%;
  margin: 0 auto;
  /* text-align: center; */
}

.custom-radio input {
  display: none;
}

.radio-btn {
  margin: 6px;
  width: 80px;
  height: 80px;
  border: 2px solid transparent;
  display: inline-block;
  border-radius: 10px;
  position: relative;
  text-align: center;
  box-shadow: 0 0 10px #c3c3c367;
  cursor: pointer;
}

.radio-btn > i {
  color: #ffffff;
  background-color: #8373e6;
  font-size: 20px;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%) scale(4);
  border-radius: 50px;
  padding: 3px;
  transition: 0.2s;
  pointer-events: none;
  opacity: 0;
}

.radio-btn .radio-icon {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.radio-btn .radio-icon i {
  color: #8373e6;
  line-height: 80px;
  font-size: 60px;
}

.radio-btn .radio-icon h3 {
  color: #8373e6;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.custom-radio input:checked + .radio-btn {
  border: 2px solid #8373e6;
}

.custom-radio input:checked + .radio-btn > i {
  opacity: 1;
  transform: translateX(-50%) scale(1);
}

.upload-btn {
  margin: 6px;
  width: 80px;
  height: 80px;
  padding: 0px;
  border: 2px solid transparent;
  display: inline-block;
  border-radius: 10px;
  position: relative;
  text-align: center;
  /* box-shadow: 0 0 10px #c3c3c367; */
  cursor: pointer;
}

/* Collapsible Section */
.collapsible {
  margin-left: 3rem;
}

.collapsible .title {
  margin-bottom: 10px;
}

.collapsible .title:hover {
  cursor: pointer;
  color: var(--bg-secondary);
  transition: var(--transition-speed);
}

.collapsible .content-parent {
  overflow: hidden;
  height: 0px;
  transition: height ease var(--transition-speed);
}

.full-height {
  height: 100%;
}

.color-selector {
}
.color-input {
  display: inline-block;
  position: relative;
}
.color-input .color-indicator {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-40%, -50%);
  cursor: pointer;
}

.edge-icon {
  width: 1.4375em;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.edge-btn {
  width: 2.5em;
  height: 2.5em;
}
