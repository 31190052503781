@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .card {
    @apply w-full flex rounded-lg shadow-md p-3 content-center;
  }
  .card-md {
    @apply grid grid-cols-1 grid-rows-2 
        justify-center content-center py-8
        rounded-lg shadow-md 
        w-44 h-52;
  }
  .card-icon {
    @apply w-14 h-14 rounded-full;
  }
  .card-icon-md {
    @apply self-center justify-self-center 
            w-20 h-20 rounded-full;
  }

  .card-active {
    @apply bg-light dark:bg-dark cursor-pointer
            border-2 border-white hover:border-link-light
            hover:shadow-lg;
  }
  .card-inactive {
    @apply bg-gray-100 cursor-default opacity-80;
  }

  .icon-active {
    @apply bg-blue-300;
  }
  .icon-inactive {
    @apply bg-gray-300;
  }
}
