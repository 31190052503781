.qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 10rem;
}

.download-btn {
  position: absolute;
  bottom: 18%;
  top: 0;
}

.right-block {
  height: 100vh;
  top: 0;
  position: sticky;
  position: -webkit-sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* @media (min-width: 800px) {
  .qrcode {
    position: fixed;
    width: 40%;
    left: 60%;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

  .settings {
    width: 60%;
  }

  .right-block {
    width: 39%;
  }
} */
