@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .selected {
    @apply border-l-4 border-primary text-primary;
  }
  .default {
    @apply text-gray-400 hover:text-gray-700 dark:hover:text-white;
  }

  .menu-link {
    @apply h-14 hover:bg-gray-100 dark:hover:bg-hover-dark;
  }

  .menu-nav {
    @apply border-r border-subtil dark:border-subtil-dark;
  }

  .nav-icon {
    @apply w-5 h-5;
  }

  .logo {
    @apply font-bold text-primary w-full;
  }
}

.menu {
  position: fixed;
  /* background-color: var(--bg-primary); */
  transition: width 200ms ease;
  z-index: 20;
}

.menu-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.menu-item {
  width: 100%;
}

.menu-item:nth-child(7) {
  margin-top: auto;
}
/* .menu-item:last-child {
    margin-top: auto;
  } */

.menu-link {
  display: flex;
  align-items: center;
  /* color: var(--text-primary); */
  text-decoration: none;
  /* filter: opacity(0.7); */
  transition: var(--transition-speed);
}

.menu-link:hover {
  filter: grayscale(0%) opacity(1);
}

.menu-link svg {
  width: 2rem;
  min-width: 2rem;
  margin: 0 1rem;
}

.link-text {
  display: none;
  margin-left: 1rem;
}

.expanded {
  display: none;
}
.collapsed {
  margin-left: 1rem;
}
.menu:hover .logo svg {
  transform: rotate(-180deg);
}

.logo {
  text-align: center;
  letter-spacing: 0.3ch;
}

.logo svg {
  transform: rotate(0deg);
  transition: var(--transition-speed);
}

.logo-text {
  display: inline;
  position: absolute;
  transition: var(--transition-speed);
}

/* Small screens */
@media only screen and (max-width: 768px) {
  .menu {
    bottom: 0;
    width: 100vw;
    height: 5rem;
  }

  .menu-nav {
    flex-direction: row;
  }

  .menu-link {
    justify-content: center;
  }

  main {
    margin: 0;
    margin-bottom: 5rem;
  }
}

/* Large screens */
@media only screen and (min-width: 768px) {
  .menu {
    top: 0;
    width: 4rem;
    height: 100vh;
  }

  .menu:hover {
    width: 13rem;
  }

  .menu:hover .link-text {
    display: inline;
    transition: opacity var(--transition-speed);
  }

  .menu:hover .expanded {
    display: inline;
    margin-left: 3rem;
    transition: var(--transition-speed);
  }
  .menu:hover .collapsed {
    display: none;
  }
}
