.qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 10rem;
}

@media (min-width: 1024px) {
  .qrcode {
    position: fixed;
    width: 40%;
    left: 60%;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }

  .settings {
    width: 60%;
  }
}

.download-btn {
  position: absolute;
  bottom: 18%;
}
