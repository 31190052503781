@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .td {
    @apply md:p-5  text-sm;
  }
  .p {
    @apply text-gray-900 whitespace-nowrap;
  }
  .tag {
    @apply text-indigo-400 font-medium leading-tight 
        inline-block align-middle text-center
        px-2 py-1 bg-indigo-100 rounded-full;
  }
  .active-wrap {
    @apply md:text-s relative inline-block px-3 py-1 font-semibold text-active-900 dark:text-active-dark-900 leading-tight;
  }
  .active {
    @apply md:text-s absolute inset-0 bg-active-200 dark:bg-active-dark-200 opacity-60 rounded-full;
  }
  .draft-wrap {
    @apply md:text-s relative inline-block px-3 py-1 font-semibold text-draft-900 dark:text-draft-dark-900 leading-tight;
  }
  .draft {
    @apply md:text-s absolute inset-0 bg-draft-200 dark:bg-draft-dark-200 opacity-60 rounded-full;
  }
  .disabled-wrap {
    @apply md:text-s relative inline-block px-3 py-1 font-semibold text-disabled-900 dark:text-disabled-dark-900 leading-tight;
  }
  .disabled {
    @apply md:text-s absolute inset-0 bg-disabled-200 dark:bg-disabled-dark-200 opacity-60 rounded-full;
  }

  .delete {
    @apply w-6 p-1  rounded border mr-1 transform hover:text-error dark:hover:text-error-dark hover:scale-110 hover:cursor-pointer;
  }

  .edit {
    @apply w-6 p-1  rounded border mr-1 transform hover:text-interactive dark:hover:text-interactive-dark hover:scale-110 hover:cursor-pointer;
  }

  .download {
    @apply w-6 p-1  rounded border mr-1 transform hover:text-success dark:hover:text-success-dark hover:scale-110 hover:cursor-pointer;
  }
}
